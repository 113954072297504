import { makeStateKey, Injectable } from '@angular/core';
import { Irequest, Iresponse, Manager } from '@core/managers/Manager';
import { Partner } from '@data/interfaces/partner-dashboard.interface';



const PARTNER_DASHABOARD = makeStateKey<any>('PARTNER_DASHABOARD');

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends Manager {

  private readonly path = "partner";
  private _partner ?: Partner;
    
  activeStudents = {
    count : (response : Iresponse) : void => {
      const request : Irequest = {
        url: `${this.path}/${this.user()!.partner_id}/active-users/count`
      };
  
      this._get(request,response)
    },
    get : (response : Iresponse) : void => {
      const request : Irequest = {
        url: `${this.path}/${this.user()!.partner_id}/active-users`
      };
  
      this._get(request,response)
    }
  }


  dashboard = (response : Iresponse) : void => {
    const request : Irequest = {
      url: `${this.path}/${this.user()!.partner_id}/dashboard`,
      key : PARTNER_DASHABOARD
    };
  
    this._get(request,response)
  }

  set partner(partner : Partner) {
    this._partner = partner;
  }

  get partner() : Partner | undefined {
    return this._partner;
  }

}
