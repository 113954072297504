import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CalendarComponent } from '@components/calendar/calendar.component';
import { PartnerService } from '@services/partner/partner.service';
import { PartnerDashboard } from '@data/interfaces/partner-dashboard.interface';
import moment, { Moment } from 'moment';
import { Rent } from '@core/classes/rent';
import { Ibook } from '@data/interfaces/user-booking.interface';
import { Utils } from '@utils/Utils';
import { RouterModule } from '@angular/router';

// Duration option interface
interface SelectOption {
  value: string | number;
  label: string;
}

@Component({
  selector: 'app-agency-portal',
  standalone: true,
  imports: [
    CommonModule, 
    FormsModule, 
    ReactiveFormsModule, 
    CalendarComponent,
    DatePipe, 
    MatIconModule, 
    MatButtonModule,
    MatTooltipModule,
    MatRippleModule,
    MatBadgeModule,
    MatFormFieldModule,
    MatSelectModule,
    RouterModule
  ],
  templateUrl: './agency-portal.component.html',
  styleUrls: ['./agency-portal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyPortalComponent {
  // Current date for header
  currentDate = signal<Date>(new Date());
  
  // Key metrics
  activeStudents = signal<number>(0);
  upcomingCheckins = signal<number>(0);
  availableUnits = signal<number>(0);

  
  nextCheckinDate = signal<string>('No upcoming check-ins');
  Utils = Utils

  // Search form variables
  checkinDate : FormControl = new FormControl(null);
  checkoutDate : FormControl = new FormControl(null);
  studentsCount : FormControl = new FormControl('1');
  
  // Carousel state
  activeCarouselIndex = signal<number>(0);
  carouselDots = signal<number[]>([0, 1, 2]);
  activeCarouselDot = signal<number>(0);
  
  // Properties data
  allproperties = signal<Rent[]>([]);
  newProperties = signal<Rent[]>([]);
  
  studentCountOptions = signal<SelectOption[]>([
    { value: 1, label: '1 student' },
    { value: 2, label: '2 students' },
    { value: 3, label: '3 students' },
    { value: 4, label: '4 students' },
    { value: '5+', label: '5+ students' }
  ]);
  
  // Upcoming student arrivals
  upcomingArrivals = signal<Ibook[]>([]);

  constructor(protected partnerService : PartnerService) {
    this.partnerService.dashboard({
      next : (result : PartnerDashboard)=>{
        this.activeStudents.set(result.active_users_count);
        this.upcomingCheckins.set(result.upcoming_users_count);
        this.availableUnits.set(result.total_bookings);
        this.newProperties.set(result.recent_listings.map(rent => new Rent(rent)).slice(0, 3));
        this.allproperties.set(result.recent_listings.map(rent => new Rent(rent)));
        this.upcomingArrivals.set(result.upcoming_users);
        this.partnerService.partner = result.partner;
        
        // Calcular días hasta el próximo check-in
        this.calculateNextCheckinDays(result.upcoming_users);
      }
    });
  }

  // Calcular días hasta el próximo check-in
  private calculateNextCheckinDays(upcomingUsers: Ibook[]): void {
    if (!upcomingUsers || upcomingUsers.length === 0) {
      this.nextCheckinDate.set('No upcoming check-ins');
      return;
    }

    const today = moment();
    let closestArrival: moment.Moment | null = null;
    let minDays = Number.MAX_VALUE;

    // Encontrar la fecha de llegada más cercana
    upcomingUsers.forEach(student => {
      if (!student.arrive) return;
      
      const arrivalDate = moment(student.arrive);
      if (arrivalDate.isValid() && arrivalDate.isAfter(today)) {
        const daysUntilArrival = arrivalDate.diff(today, 'days');
        if (daysUntilArrival < minDays) {
          minDays = daysUntilArrival;
          closestArrival = arrivalDate;
        }
      }
    });

    // Formatear la fecha según la proximidad
    if (closestArrival) {
      if (minDays === 0) {
        this.nextCheckinDate.set('Today');
      } else if (minDays === 1) {
        this.nextCheckinDate.set('Tomorrow');
      } else if (minDays < 7) {
        this.nextCheckinDate.set(`In next ${minDays} days`);
      } else if (minDays < 30) {
        const weeks = Math.floor(minDays / 7);
        this.nextCheckinDate.set(weeks === 1 ? 'In next 1 week' : `In next ${weeks} weeks`);
      } else if (minDays < 365) {
        const months = Math.floor(minDays / 30);
        this.nextCheckinDate.set(months === 1 ? 'In next 1 month' : `In next ${months} months`);
      } else {
        // Más de un año, mostrar la fecha completa
        this.nextCheckinDate.set((closestArrival as Moment).format('MMM D, YYYY'));
      }
    } else {
      this.nextCheckinDate.set('No upcoming check-ins');
    }
  }

  // Property carousel methods
  previousProperties(): void {
    // Si estamos mostrando el "see more", volvemos a mostrar las últimas propiedades
    if (this.newProperties().length === 0) {
      const lastGroupIndex = Math.floor((this.allproperties().length - 1) / 3);
      const startIndex = lastGroupIndex * 3;
      this.newProperties.set(this.allproperties().slice(startIndex, startIndex + 3));
      this.activeCarouselIndex.set(lastGroupIndex);
      this.activeCarouselDot.set(lastGroupIndex);
      return;
    }
    
    const current = this.activeCarouselIndex();
    const newIndex = current === 0 ? Math.floor((this.allproperties().length - 1) / 3) : current - 1;
    const startIndex = newIndex * 3;
    this.newProperties.set(this.allproperties().slice(startIndex, startIndex + 3));
    this.activeCarouselIndex.set(newIndex);
    this.activeCarouselDot.set(newIndex);
  }
  
  nextProperties(): void {
    const current = this.activeCarouselIndex();
    const totalGroups = Math.ceil(this.allproperties().length / 3);
    
    // Si ya estamos en la última página de propiedades, mostramos "see more"
    if (current === totalGroups - 1) {
      this.newProperties.set([]);
      this.activeCarouselIndex.set(totalGroups);
      this.activeCarouselDot.set(current + 1);
      return;
    }
    
    // Si estamos en "see more", volvemos a la primera página
    if (this.newProperties().length === 0) {
      const firstProperties = this.allproperties().slice(0, 3);
      this.newProperties.set(firstProperties);
      this.activeCarouselIndex.set(0);
      this.activeCarouselDot.set(0);
      return;
    }
    
    // Avanzamos a la siguiente página de propiedades
    const newIndex = current + 1;
    const startIndex = newIndex * 3;
    this.newProperties.set(this.allproperties().slice(startIndex, startIndex + 3));
    this.activeCarouselIndex.set(newIndex);
    this.activeCarouselDot.set(newIndex);
  }
  
  goToSlide(index: number): void {
    const totalGroups = Math.ceil(this.allproperties().length / 3);
    
    // Si seleccionamos el último dot, mostramos "see more"
    if (index >= totalGroups) {
      this.newProperties.set([]);
    } else {
      // De lo contrario, mostramos el grupo correspondiente
      const startIndex = index * 3;
      this.newProperties.set(this.allproperties().slice(startIndex, startIndex + 3));
    }
    
    this.activeCarouselIndex.set(index);
    this.activeCarouselDot.set(index);
  }

  // Availability search method
  searchAccommodation(): void {
    console.log('Searching for accommodation');
    // Implementation would perform search and display results
  }

  isNewProperty(property: Rent): boolean {
    const d : string = property.getRentRoom().date_creation!;
    const date : Moment = moment(d);
    return moment().add(-15, 'days').isBefore(date);
  }
  
  // Get status icon based on status
  getStatusIcon(status: string): string {
    switch(status) {
      case 'CONFIRMED':
        return 'check_circle';
      case 'PENDING':
        return 'hourglass_empty';
      case 'PROCESSING':
        return 'sync';
      default:
        return 'info';
    }
  }
  
  // Helper method to safely get the address from a listing
  getListingAddress(listing: any): string {
    if (typeof listing === 'number') {
      return 'Address not available';
    }
    return listing?.location?.address || 'Address not available';
  }
  
  // Navigate to view all student arrivals
  viewAllArrivals(): void {
    console.log('Navigating to all student arrivals');
    // Implementation would navigate to a dedicated student arrivals page
    // or open a modal with all arrivals
  }
}
