<!-- Agency Portal Dashboard -->

<section class="main-section">
  
  <div class="section-container">
    <div class="dashboard-header">
      <header class="header-content">
          <h1 class="page-title">Welcome {{partnerService.partner?.name}}</h1>
          <p class="subtitle">Manage and monitor all your student accommodation</p>
      </header>
    </div>
    <div class="dashboard-layout">
      <div class="main-content">
        <!-- Key Metrics Section -->
        <div class="metrics-container">
          <div class="metric-card" matRipple role="region" aria-label="Active Students">
            <mat-icon class="metric-icon">people</mat-icon>
            <h2 class="metric-value">{{ activeStudents() }}</h2>
            <p class="metric-label">Active Students</p>
            <p class="metric-detail">In the properties</p>
          </div>
      
          <div class="metric-card" matRipple role="region" aria-label="Upcoming Check-ins">
            <mat-icon class="metric-icon">event_available</mat-icon>
            <h2 class="metric-value">{{ upcomingCheckins() }}</h2>
            <p class="metric-label">Upcoming Check-ins</p>
            <p class="metric-detail">{{ nextCheckinDate() }}</p>
          </div>
      
          <div class="metric-card" matRipple role="region" aria-label="Available Units">
            <mat-icon class="metric-icon">apartment</mat-icon>
            <h2 class="metric-value">{{ availableUnits() }}</h2>
            <p class="metric-label">Total Bookings</p>
            <p class="metric-detail">Up to date</p>
          </div>
        </div>
      
        <!-- Quick Actions Section -->
        <section class="quick-actions-section">
          <h2><mat-icon>flash_on</mat-icon> Quick Actions</h2>
          <div class="quick-actions-container">
            <a class="action-card" matRipple role="button" tabindex="0">
              <div class="icon-container">
                <mat-icon class="action-icon">add_circle</mat-icon>
              </div>
              <div class="action-text">
                <h3>New Booking Request</h3>
                <p>Place new student request</p>
              </div>
            </a>
      
            <a [routerLink]="['../property-catalog']" class="action-card" matRipple role="button" tabindex="0">
              <div class="icon-container">
                <mat-icon class="action-icon">search</mat-icon>
              </div>
              <div class="action-text">
                <h3>Check Availability</h3>
                <p>Search all properties</p>
              </div>
            </a>
      
            <a class="action-card" matRipple role="button" tabindex="0">
              <div class="icon-container">
                <mat-icon class="action-icon">headset_mic</mat-icon>
              </div>
              <div class="action-text">
                <h3>Contact Support</h3>
                <p>Direct line support</p>
              </div>
            </a>
          </div>
        </section>

        <section class="availability-search-section">
          <h2><mat-icon>calendar_today</mat-icon> Quick Availability Search</h2>
          <div class="search-form-container">
            <h3>Find Accommodation for Your Students</h3>
            <form class="search-form" (ngSubmit)="searchAccommodation()">
              <div class="form-fields">
                <app-calendar [startDate]="checkinDate" [endDate]="checkoutDate"></app-calendar>
                
                <div class="form-group">
                  <mat-form-field appearance="outline" style="border-color: var(--mon-gray-light);">
                    <mat-label>
                      <mat-icon>group</mat-icon> Students
                    </mat-label>
                    <mat-select [formControl]="studentsCount" style="font-family: Montserrat;">
                      @for (option of studentCountOptions(); track $index) {
                        <mat-option style="font-family: Montserrat;" [value]="option.value">{{ option.label }}</mat-option>
                      }
                    </mat-select>
                  </mat-form-field>
                </div>
                <button mat-raised-button color="primary" type="submit" class="search-button">
                  <mat-icon>search</mat-icon> Search now
                </button>
              </div>
            </form>
          </div>
        </section>
      
        <!-- New Properties Section -->
        <section class="properties-section">
          <div class="section-header">
            <h2><mat-icon>apartment</mat-icon> New Properties Available</h2>
            <p class="section-subheader">Recent Properties or availability nearby</p>
          </div>
      
          <div class="properties-carousel">
            <!-- Left Arrow -->
            <button [disabled]="activeCarouselIndex() === 0 || allproperties().length === 0" mat-icon-button class="carousel-arrow left" aria-label="Previous properties" (click)="previousProperties()">
              <mat-icon>chevron_left</mat-icon>
            </button>
            
            <!-- Property Cards -->
            <div class="property-cards-container">
              @if (newProperties().length > 0) {
                @for (property of newProperties(); track $index) {
                  <div class="property-card" matRipple>
                    @if (isNewProperty(property)) {
                      <div class="new-badge">NEW</div>
                    }
                    <div class="property-image">
                      <img style="object-fit: cover; height: 175px; width: 100%;" [src]="property.getMainImage()" alt="Property Image">
                    </div>
                    <h3>{{ property.getTitle() }}</h3>
                    <p>{{ property.getRentRoom().location.address }}</p>
                    <p class="property-price" style="margin-bottom: 0;">From {{property.getSymbol()}}{{ property.getPrice() }}/{{property.getRentRoom().price.time}}</p>
                    <p class="property-price">Check-in {{property.getAvailableFrom()}}</p>
                    <a target="_blank" [href]="property.getURL(true)" mat-raised-button color="primary" class="view-details-btn">
                      <mat-icon>visibility</mat-icon> VIEW DETAILS
                    </a>
                  </div>
                }
              } @else {
                <!-- See More Card (when no properties available) -->
                <div class="see-more-card" matRipple>
                  <div class="see-more-content">
                    <mat-icon class="see-more-icon">explore</mat-icon>
                    <h3>Explore Our Full Property Portfolio</h3>
                    <p>Looking for more student housing options? Discover all our available accommodations with detailed information, pricing, and amenities.</p>
                    <a routerLink="../property-catalog" mat-raised-button class="see-more-btn">
                      <mat-icon>search</mat-icon> <span style="font-family: Montserrat;">VIEW ALL PROPERTIES</span>
                    </a>
                  </div>
                </div>
              }
            </div>
      
            <!-- Right Arrow -->
            <button [disabled]="activeCarouselIndex() === 2 || allproperties().length === 0" mat-icon-button class="carousel-arrow right" aria-label="Next properties" (click)="nextProperties()">
              <mat-icon>chevron_right</mat-icon>
            </button>
      
            <!-- Carousel Dots -->
            <div class="carousel-dots">
              @for (dot of carouselDots(); track dot) {
                <div class="dot" [class.active]="dot === activeCarouselDot()" (click)="goToSlide(dot)"></div>
              }
            </div>
          </div>
        </section>
      
      </div>
      
      <!-- Student Arrivals Sidebar -->
      <aside class="student-arrivals-sidebar">
        <div class="sidebar-card">
          <div class="sidebar-header">
            <mat-icon class="sidebar-icon">flight_land</mat-icon>
            <h2>Upcoming Student Arrivals</h2>
          </div>
          
          <div class="arrivals-list-container">
            @if (upcomingArrivals().length > 0) {
              <div class="arrivals-list">
                @for (student of upcomingArrivals(); track $index) {
                  <div class="arrival-item">
                    <div class="arrival-header">
                      <div class="student-name">
                        <mat-icon>person</mat-icon>
                        <h3>{{ student.full_name }}</h3>
                      </div>
                      <div class="student-name">
                        <mat-icon>{{student.gender}}</mat-icon>
                        <h3>{{ Utils.capitalize(student.gender) }}</h3>
                      </div>
                    </div>
                    <div class="arrival-details">
                      <div class="detail-item">
                        <mat-icon>location_on</mat-icon>
                        <span style="font-family: Montserrat;">{{ getListingAddress(student.listing) }}</span>
                      </div>
                      <div class="detail-item">
                        <mat-icon>today</mat-icon>
                        <span style="font-family: Montserrat;">{{ student.arrive | date:'MMMM d, yyyy' }}</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
              <div class="view-all-container">
                <button mat-button color="primary" class="view-all-btn" (click)="viewAllArrivals()">
                  <span style="font-family: Montserrat;">View All Arrivals</span>
                  <mat-icon>arrow_forward</mat-icon>
                </button>
              </div>
            }
            @else {
              <div class="no-arrivals">
                <mat-icon>info</mat-icon>
                <p>No upcoming arrivals found</p>
              </div>
            }
          </div>
        </div>
      </aside>
    </div>
  </div>
</section>
