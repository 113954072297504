import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-agency-bookings',
  standalone: true,
  imports: [],
  templateUrl: './agency-bookings.component.html',
  styleUrls: ['./agency-bookings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgencyBookingsComponent { }
