import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MenuGroup } from '@core/interfaces/routes.interface';
import { AgencyPortalComponent } from './agency-portal.component';
import { ListingsComponent } from '@modules/my-properties/listings/listings.component';
import { AgencyBookingsComponent } from './agency-bookings/agency-bookings.component';


export const AGENCY_PORTAL  : MenuGroup = {
  title: 'My portal',
  expanded: true,
  parent : 'my-portal',
  icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                </svg>`,
  items :  [
    {
      title: 'Overview',
      path: 'overview',
      component : AgencyPortalComponent,
      data : {
        icon : `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <line x1="18" y1="20" x2="18" y2="10"></line>
                  <line x1="12" y1="20" x2="12" y2="4"></line>
                  <line x1="6" y1="20" x2="6" y2="14"></line>
                </svg>`
      }
    },

    {
      title: 'Property Catalog',
      path: 'property-catalog',
      component: ListingsComponent,
      data : {
        icon: `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2">
                  <line x1="8" y1="6" x2="21" y2="6"></line>
                  <line x1="8" y1="12" x2="21" y2="12"></line>
                  <line x1="8" y1="18" x2="21" y2="18"></line>
                  <line x1="3" y1="6" x2="3.01" y2="6"></line>
                  <line x1="3" y1="12" x2="3.01" y2="12"></line>
                  <line x1="3" y1="18" x2="3.01" y2="18"></line>
                </svg>`
      }
    },
    {
      title: 'Bookings',
      path: 'bookings',
      component: AgencyBookingsComponent,
      data : {
        icon : `<svg style="fill: var(--mon-gray);" width="18" height="18" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 352c97.2 0 176-78.8 176-176S433.2 0 336 0S160 78.8 160 176c0 18.7 2.9 36.8 8.3 53.7L7 391c-4.5 4.5-7 10.6-7 17l0 80c0 13.3 10.7 24 24 24l80 0c13.3 0 24-10.7 24-24l0-40 40 0c13.3 0 24-10.7 24-24l0-40 40 0c6.4 0 12.5-2.5 17-7l33.3-33.3c16.9 5.4 35 8.3 53.7 8.3zM376 96a40 40 0 1 1 0 80 40 40 0 1 1 0-80z"/></svg>`
      }
    }
    
  ]
}

const routes: Routes = [
  ...AGENCY_PORTAL.items,
  {
    path: '',
    redirectTo: 'overview',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes),],
  exports: [RouterModule]
})
export class AgencyPortalRouting {


}
